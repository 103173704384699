$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1200px;
$value_eight: 1267px;

@media only #{$media} and ($feature_max : $value_two) {

    body {
        font-size: 13px;
    }
    p {
        font-size: 13px;
    }
    .ptb-100 {
        padding: {
            top: 60px;
            bottom: 60px;
        };
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .section-title {
        max-width: 100%;
        margin: {
            bottom: 40px;
            left: 0;
            right: 0;
        };
        .sub-title {
            margin-bottom: 8px;
            font-size: 15px;
        }
        h2 {
            font-size: 25px;
        }
        p {
            max-width: 100%;
            font-size: 13px;
            margin: {
                left: 0;
                top: 8px;
                bottom: 0;
                right: 0;
            };
        }
    }
    .default-btn {
        font-size: 13px;
        padding: {
            left: 30px;
            right: 30px;
            top: 13px;
            bottom: 12px;
        };
    }
    .optional-btn {
        font-size: 13px;
        padding: {
            left: 30px;
            right: 30px;
            top: 13px;
            bottom: 12px;
        };
    }
    .form-control {
        text-transform: capitalize;
        padding-left: 10px;
        font-size: 14px;
        font-weight: 400;
    }

    .top-header {
        text-align: center;
        padding: {
            top: 15px;
            bottom: 15px;
        };
        .top-header-nav {
            li {
                &::before {
                    top: 7px;
                }
            }
        }
        .top-header-right-side {
            text-align: center;
            margin-top: 10px;

            ul {
                li {
                    text-align: center;
                    padding-left: 0;
                    font-size: 13px;
                    margin: {
                        right: 10px;
                        left: 10px;
                    };
                    .icon {
                        display: none;
                    }
                    span {
                        margin-bottom: 1px;
                    }
                    a {
                        font-size: 16px;

                        &.default-btn {
                            top: 5px;
                            font-size: 13px;
                        }
                    }
                    &:last-child {
                        // display: none;
                        text-align: center;
                        padding-left: 0;
                        font-size: 13px;
                        margin-right: 10px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .top-header {
        .top-header-logo {
            display: none;
        }
    }
    .header-style-three {
        .top-header {
            .top-header-right-side {
                margin-top: 0;
            }
        }
    }
    .header-style-four {
        .top-header {
            .top-header-right-side {
                margin-top: 0;
            }
        }
    }

    .main-banner {
        height: 100%;
        padding: {
            top: 275px;
            bottom: 180px;
        };
        &::after {
            display: none;
        }
    }
    .main-banner-content {
        margin-top: 0;
        max-width: 100%;
        text-align: center;

        .sub-title {
            margin-bottom: 10px;
            font-size: 14px;
        }
        h1 {
            line-height: 1.3;
            font-size: 28px;

            br {
                display: none;
            }
        }
        p {
            max-width: 100%;
        }
        .btn-box {
            margin-top: 25px;

            .default-btn {
                margin-right: 0;
                display: block;
                width: 100%;
            }
            .optional-btn {
                display: block;
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    .banner-section {
        height: 100%;
        padding: {
            top: 275px;
            bottom: 150px;
        };
        .main-banner-content {
            margin-top: 0;
        }
    }
    .banner-content {
        margin-top: 0;
        max-width: 100%;

        .sub-title {
            font-size: 14px;
        }
        h1 {
            font-size: 28px;
            line-height: 1.3;

            br {
                display: none;
            }
        }
        p {
            margin-top: 13px;
            max-width: 100%;
        }
        .btn-box {
            .default-btn {
                margin-right: 15px;
                padding: {
                    left: 26px;
                    bottom: 13px;
                    right: 26px;
                };
            }
            .video-btn {
                top: 1px;
                font-size: 14px;
            }
        }
    }
    .home-slides {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 15px;
                    top: auto;
                    transform: unset;
                    font-size: 25px;
                    bottom: 60px;

                    &.owl-next {
                        left: auto;
                        right: 15px;
                    }
                }
            }
        }
    }
    .home-slides-two {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 15px;
                    top: auto;
                    transform: unset;
                    font-size: 25px;
                    bottom: 20px;

                    &.owl-next {
                        left: auto;
                        right: 15px;
                    }
                }
            }
        }
    }
    .main-banner-section {
        height: 100%;
        padding: {
            top: 275px;
            bottom: 185px;
        };
        .main-banner-content {
            margin-top: 0;
        }
    }
    .shape1, .shape2, .shape3, .shape4 {
        display: none;
    }

    .services-boxes-area {
        margin: {
            bottom: -30px;
            top: -50px;
        };
    }
    .single-box {
        margin-bottom: 30px;

        .image {
            img {
                width: 100%;
            }
        }
        .content {
            padding: 60px 30px 35px;
            border-radius: 100% 100% 0 0;

            h3 {
                margin-bottom: 10px;
                font-size: 16px;
            }
            .shape {
                display: none;
            }
            .icon {
                display: none;
            }
        }
    }

    .about-title {
        border-right: none;
        padding-right: 0;
        text-align: center;

        span {
            font-size: 15px;
            margin-bottom: 8px;
        }
        h2 {
            font-size: 25px;
        }
    }
    .about-text {
        padding-left: 0;
        text-align: center;
        margin-top: 10px;

        p {
            font-size: 13px;
        }
        .read-more-btn {
            margin-top: 3px;
            font-size: 13px;
        }
    }
    .about-boxes-area {
        margin-top: 10px;
    }
    .single-about-box {
        padding: 20px;

        .icon {
            width: 70px;
            margin-bottom: 20px;
            height: 70px;
            line-height: 70px;
            font-size: 40px;
        }
        h3 {
            margin-bottom: 8px;
            font-size: 16px;
        }
    }
    .about-image {
        padding-bottom: 0;

        img {
            width: 100%;

            &:nth-child(2) {
                position: relative;
                right: 0;
                bottom: 0;
                border-radius: 0;
                margin-top: 20px;
            }
        }
    }
    .about-content {
        padding-left: 0;
        text-align: center;
        margin-top: 30px;

        span {
            margin-bottom: 8px;
            font-size: 15px;
        }
        h2 {
            font-size: 25px;
        }
    }
    .about-inner-area {
        margin-top: 10px;
    }
    .about-text-box {
        padding: 25px;

        h3 {
            font-size: 16px;
        }
        p {
            font-size: 13px;
        }
    }
    .about-img {
        padding: {
            right: 0;
            left: 0;
            bottom: 0;
        };
        img {
            width: 100%;
        }
        .shape {
            display: none;
        }
        .text {
            bottom: 0;
            font-size: 14px;
            padding: {
                top: 10px;
                bottom: 10px;
                left: 14px;
                right: 14px;
            };
            span {
                font-size: 16px;
            }
        }
    }

    .single-services-box {
        padding: 25px;

        .icon {
            font-size: 50px;
            margin-top: -12px;
        }
        h3 {
            margin-bottom: 16px;
            font-size: 16px;
        }
        .read-more-btn {
            margin-top: 5px;
            font-size: 13px;
        }
    }
    .services-slides {
        &.owl-theme {
            .owl-nav {
                opacity: 1;
                visibility: visible;

                [class*=owl-] {
                    top: auto;
                    transform: unset;
                    bottom: -13px;
                    left: 0;
                    font-size: 16px;
                    width: 35px;
                    height: 35px;
                    line-height: 36px;

                    &.owl-next {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
    .services-box {
        .content {
            padding: 20px;

            h3 {
                font-size: 16px;
            }
            .read-more-btn {
                margin-top: 0;
                font-size: 13px;
            }
        }
    }

    .partner-area {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .partner-title {
        h2 {
            margin-bottom: 35px;
            font-size: 17px;
        }
    }

    .why-choose-us-image {
        height: auto;
        background: {
            image: unset !important;
        };
        img {
            width: auto !important;
            display: inline-block !important;
        }
    }
    .why-choose-us-slides {
        height: auto;

        .owl-stage-outer {
            height: auto;

            .owl-stage {
                height: auto;

                .owl-item {
                    height: auto;
                }
            }
        }
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 15px;
                    font-size: 25px;

                    &.owl-next {
                        left: auto;
                        right: 15px;
                    }
                }
            }
        }
    }
    .why-choose-us-content {
        background-image: unset !important;

        .content {
            max-width: 100%;
            padding: {
                left: 15px;
                top: 60px;
                bottom: 60px;
                right: 15px;
            };
            .title {
                text-align: center;

                .sub-title {
                    font-size: 15px;
                    margin-bottom: 8px;
                }
                h2 {
                    font-size: 25px;
                }
                p {
                    font-size: 13px;
                }
            }
            .features-list {
                text-align: center;
                margin: {
                    left: -10px;
                    right: -10px;
                };
                li {
                    padding: {
                        left: 10px;
                        right: 10px;
                    };
                    .icon {
                        position: relative;
                        left: 0;
                        top: 0;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        font-size: 22px;
                        margin: {
                            left: auto;
                            right: auto;
                            bottom: 15px;
                        };
                    }
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .quote-content {
        padding-right: 0;

        h2 {
            font-size: 25px;
        }
        p {
            font-size: 13px;
            margin-top: 7px;
        }
        .image {
            margin-top: 30px;
        }
    }
    .quote-list-tab {
        margin-left: 0;
        padding: 20px;
        margin-top: 35px;

        .tabs {
            li {
                font-size: 13px;

                a {
                    padding: 9px 16.1px 7px;
                }
            }
        }
        .tabs_item {
            padding: 15px;

            p {
                font-size: 13px;
            }
            form {
                .nice-select {
                    text-transform: capitalize;
                    padding: 0 10px;
                    font-weight: 400;

                    &:after {
                        right: 10px;
                        width: 7px;
                        height: 7px;
                    }
                }
            }
        }
    }
    .quote-boxes-area {
        margin-top: -69px;
    }
    .quote-boxes-content {
        max-width: 100%;
        padding: 25px;
        margin: {
            left: 0;
            right: 0;
        };
        .image {
            display: none;
        }
        h2 {
            padding: 25px 25px 20px;
            font-size: 20px;
            margin: {
                top: -25px;
                right: -25px;
                left: -25px;
                bottom: 25px;
            };
        }
        form {
            .form-group {
                margin-bottom: 15px;
            }
            .default-btn {
                margin-top: 0;
                display: block;
                width: 100%;
            }
            .nice-select {
                text-transform: capitalize;
                padding: 0 10px;
                font-weight: 400;

                &:after {
                    right: 10px;
                    width: 7px;
                    height: 7px;
                }
            }
        }
    }

    .ctr-area {
        padding: {
            top: 60px;
            bottom: 190px;
        };
    }
    .ctr-content {
        padding: 25px;
        text-align: center;
        max-width: 100%;

        h2 {
            font-size: 25px;
        }
        p {
            font-size: 13px;
            margin-top: 5px;
        }
        .default-btn {
            margin-top: 10px;
        }
    }
    .ctr-image {
        position: absolute;
        right: 0;
        top: auto;
        transform: unset;
        bottom: 0;
    }

    .single-case-study-box {
        .case-study-info {
            padding: 20px;

            .category {
                font-size: 14px;
            }
            .title {
                font-size: 16px;
            }
        }
        &:hover {
            transform: scale(1);
        }
    }

    .case-study-details-desc {
        h3 {
            font-size: 19px;
        }
        .features-text {
            h4 {
                font-size: 14px;

                i {
                    font-size: 14px;
                }
            }
        }
        .case-study-details-info {
            margin-top: 15px;

            .single-info-box {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 15px;

                h4 {
                    margin-bottom: 6px;
                    font-size: 17px;
                }
            }
        }
    }

    .feedback-slides {
        .client-feedback {
            .single-feedback {
                padding-top: 55px;
                margin-bottom: 25px;

                &::before {
                    margin-top: -20px;
                    font-size: 55px;
                }
                p {
                    max-width: 100%;
                    font-size: 17px;
                    margin: {
                        left: 0;
                        right: 0;
                    };
                }
            }
        }
        .client-thumbnails {
            .item {
                .title {
                    h3 {
                        font-size: 15px;
                    }
                    span {
                        font-size: 13px;
                        margin-top: 4px;
                    }
                }
            }
        }
        .next-arrow, .prev-arrow {
            top: 170%;
            transform: translateY(-170%);
            font-size: 22px;
            opacity: 1;
            visibility: visible;
        }
        .prev-arrow {
            left: -10px;
        }
        .next-arrow {
            right: -10px;
        }
    }
    .single-feedback-item {
        .feedback-desc {
            padding: {
                left: 25px;
                bottom: 25px;
                right: 25px;
                top: 30px;
            };
            p {
                font-size: 15px;
            }
        }
        &::before {
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 27px;
        }
        .client-info {
            h3 {
                font-size: 15px;
            }
            span {
                font-size: 13px;
            }
        }
    }

    .mission-image {
        background-image: unset;
        height: auto;

        img {
            display: inline-block;
        }
    }
    .mission-text {
        padding: {
            left: 15px;
            right: 15px;
            bottom: 30px;
            top: 30px;
        };
        .icon {
            font-size: 45px;
            margin-top: -13px;
        }
        h3 {
            font-size: 16px;
        }
    }

    .single-events-box {
        .events-box {
            .events-image {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                border-radius: 5px 5px 0 0;

                .image {
                    background-image: unset !important;
                    height: auto;

                    img {
                        display: inline-block;
                    }
                }
            }
            .events-content {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;

                .content {
                    padding: 25px;

                    h3 {
                        margin-bottom: 9px;
                        font-size: 16px;
                        line-height: 1.4;
                    }
                }
            }
            .events-date {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                border-radius: 0 0 5px 5px;

                .date {
                    height: auto;
                    border-radius: 0 0 5px 5px;
                    font-size: 20px;
                    padding: 25px 20px;

                    h3 {
                        font-size: 22px;
                        margin: {
                            bottom: 5px;
                            top: 2px;
                        };
                    }
                }
            }
        }
    }

    .events-details-header {
        .back-all-events {
            font-size: 12px;
        }
        h3 {
            margin-bottom: 12px;
            font-size: 20px;
        }
        .events-info-meta {
            li {
                margin-right: 8px;
            }
        }
    }

    .find-agent-content {
        padding-right: 0;

        .box {
            text-align: center;
            padding-left: 20px;

            .icon {
                font-size: 30px;
                position: relative;
                width: 65px;
                border-radius: 50%;
                height: 65px;
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 15px;
                };
            }
            h3 {
                font-size: 16px;
            }
        }
    }
    .find-agent-contact-info {
        padding-left: 0;
        margin-top: 35px;

        ul {
            text-align: center;

            li {
                display: inline-block;
                padding-left: 0;
                margin: {
                    top: 0;
                    bottom: 0;
                    right: 7px;
                    left: 7px;
                };;
                .icon {
                    position: relative;
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    font-size: 25px;
                    top: 0;
                    transform: unset;
                    left: 0;
                    margin: {
                        bottom: 10px;
                        left: auto;
                        right: auto;
                    };
                }
                span {
                    font-size: 13px;
                    margin-bottom: 3px;
                }
                a {
                    font-size: 16px;
                }
                cite {
                    left: 20px;
                }
                &:nth-child(2) {
                    display: none;
                }
                &::before {
                    display: none;
                }
            }
        }
    }

    .single-team-box {
        .content {
            h3 {
                font-size: 16px;
            }
            span {
                margin-top: 6px;
                font-size: 13px;
            }
        }
    }
    .team-slides {
        &.owl-theme {
            .owl-nav {
                opacity: 1;
                visibility: visible;

                [class*=owl-] {
                    left: 0;
                    top: auto;
                    transform: unset;
                    font-size: 20px;
                    bottom: -11px;

                    &.owl-next {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }

    .achievements-content {
        padding: {
            top: 60px;
            bottom: 60px;
            left: 15px;
            right: 15px;
        };
        .title {
            margin-bottom: 0;

            .sub-title {
                font-size: 15px;
                margin-bottom: 8px;
            }
            h2 {
                font-size: 25px;
            }
            p {
                font-size: 13px;
                max-width: 100%;
                margin: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }
    .single-funfact {
        i {
            font-size: 35px;
            margin: {
                bottom: 2px;
                top: -7px;
            };
        }
        h3 {
            font-size: 25px;

            .sign-icon {
                font-size: 25px;
                top: 4px;
            }
        }
        p {
            font-size: 13px;
        }
    }
    .single-achievements-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block !important;
        }
    }
    .achievements-image-slides {
        height: auto;

        .owl-stage-outer {
            height: auto;

            .owl-stage {
                height: auto;

                .owl-item {
                    height: auto;
                }
            }
        }
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 15px;
                    font-size: 25px;

                    &.owl-next {
                        left: auto;
                        right: 15px;
                    }
                }
            }
        }
    }
    .divider {
        display: none;
    }

    .faq-contact-form {
        form {
            .default-btn {
                font-size: 14px;
            }
        }
    }
    .faq-image {
        height: auto;
        background-image: unset;

        img {
            display: inline-block;
        }
    }
    .faq-accordion {
        text-align: center;
        padding: {
            top: 40px;
            bottom: 0;
            left: 15px;
            right: 15px;
        };
        .sub-title {
            margin-bottom: 8px;
            font-size: 15px;
        }
        h2 {
            max-width: 100%;
            font-size: 25px;
        }
        .accordion {
            text-align: left;

            .accordion-title {
                font-size: 15px;
            }
            .accordion-content {
                font-size: 13px;
            }
        }
    }

    .single-pricing-box {
        .pricing-header {
            padding: {
                top: 55px;
                bottom: 55px;
            };
            h3 {
                font-size: 20px;
            }
        }
        .price {
            font-size: 30px;

            sub {
                font-size: 14px;
            }
        }
        .pricing-features-list {
            padding: {
                left: 30px;
                right: 30px;
            };
            li {
                font-size: 14px;
            }
        }
    }

    .single-blog-post {
        .post-image {
            .date {
                padding: 10px 20px 0;
                font-size: 13px;
            }
        }
        .post-content {
            padding: 20px;

            h3 {
                font-size: 16px;
            }
            .default-btn {
                margin-top: 18px;
            }
        }
    }
    .blog-notes {
        margin-top: 0;

        p {
            line-height: 1.7;
        }
    }

    .blog-details-desc {
        .article-content {
            .entry-meta {
                margin-bottom: -10px;
            }
            h3 {
                margin-bottom: 13px;
                font-size: 19px;
                line-height: 1.3;
            }
        }
        .article-footer {
            text-align: center;

            .article-tags {
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                flex: 0 0 100%;
                max-width: 100%;
            }
            .article-share {
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 15px;

                .social {
                    text-align: center;
                }
            }
        }
    }
    .comments-area {
        padding: 20px;

        .comment-author {
            .avatar {
                left: 0;
                position: relative;
                display: block;
                margin-bottom: 10px;
            }
        }
        .comment-body {
            padding-left: 0;

            p {
                font-size: 13px;
            }
        }
        .comments-title {
            margin-bottom: 20px;
            font-size: 19px;

            &::before {
                left: -20px;
            }
        }
        .comment-author {
            margin-bottom: 0.3em;
            font-size: 16px;
        }
        .comment-metadata {
            font-size: 13px;
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 19px;

                &::before {
                    left: -20px;
                }
            }
            label {
                font-size: 15px;
            }
            .comment-form-author {
                width: 100%;
                padding-right: 0;
            }
            .comment-form-email {
                width: 100%;
                padding-left: 0;
            }
            .form-submit {
                input {
                    font-size: 15px;
                    padding: 12px 25px 10px;
                }
            }
        }
    }

    .page-title-area {
        height: 100%;
        padding: {
            top: 255px;
            bottom: 70px;
        };
    }
    .page-title-content {
        margin-top: 0;

        h2 {
            font-size: 28px;
        }
        ul {
            margin-top: 10px;

            li {
                font-size: 14px;

                &::before {
                    top: 9px;
                }
            }
        }
    }

    .insurance-details-header {
        .content {
            h3 {
                margin-bottom: 12px;
                font-size: 20px;
                line-height: 1.3;
            }
        }
        .image {
            margin-top: 30px;
        }
    }
    .insurance-details-desc {
        h3 {
            margin-bottom: 12px;
            font-size: 20px;
            line-height: 1.3;
        }
    }
    blockquote, .blockquote {
        padding: 25px !important;

        p {
            font-size: 17px !important;
        }
    }
    .prev-link-wrapper {
        width: 100%;

        .prev-title {
            max-width: 210px;
            display: inline-block;
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            font-size: 16px;
        }
    }
    .next-link-wrapper {
        margin-top: 15px;

        .next-title {
            max-width: 210px;
            display: inline-block;
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            font-size: 16px;
        }
    }

    .pagination-area {
        margin-top: 15px;

        .page-numbers {
            width: 40px;
            height: 40px;
            margin: 0 2px;
            line-height: 44px;
            font-size: 17px;
        }
    }

    .error-area {
        height: 100%;
        padding: {
            top: 120px;
            bottom: 120px;
        };
    }
    .error-content {
        h3 {
            font-size: 30px;
            margin-bottom: 10px;
        }
    }

    .widget-area {
        margin-top: 40px;

        .widget {
            .widget-title {
                font-size: 16px;
            }
        }
        .widget_truz_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 14px;
                    }
                }
            }
        }
        .widget_categories {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
        .widget_recent_comments {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
        .widget_recent_entries {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
        .widget_archive {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
        .widget_meta {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
        .tagcloud {
            a {
                font-size: 13px !important;
            }
        }
    }

    .contact-form {
        form {
            .default-btn {
                font-size: 14px;
            }
        }
    }
    #map {
        width: 100%;
        height: 400px;
        margin-top: 35px;
    }
    .contact-info {
        margin-top: 35px;

        .contact-info-content {
            h3 {
                font-size: 16px;
                line-height: 1.3;
            }
            h2 {
                font-size: 25px;

                span {
                    font-size: 15px;
                }
            }
            .social {
                li {
                    a {
                        line-height: 37px;
                    }
                }
            }
        }
    }

    .subscribe-area {
        text-align: center;
    }
    .subscribe-content {
        h2 {
            font-size: 25px;
        }
    }
    .subscribe-form {
        padding-left: 0;
        margin-top: 20px;

        form {
            .input-newsletter {
                height: 60px;
                font-size: 15px;
            }
            button {
                position: relative;
                right: 0;
                top: 0;
                font-size: 14px;
                margin-top: 10px;
                display: block;
                width: 100%;
            }
        }
    }

    .join-area {
        margin-top: 60px;
        padding-top: 60px;

        .col-lg-5 {
            order: 2;
        }
        .col-lg-7 {
            order: 1;
        }
        &::before {
            height: 100%;
        }
    }
    .join-content {
        margin-bottom: 30px;
        text-align: center;
        top: 0;
        max-width: 100%;

        h2 {
            font-size: 25px;
        }
    }

    .footer-area {
        padding-top: 60px;
    }
    .single-footer-widget {
        h3 {
            font-size: 16px;
        }
        .footer-contact-info {
            li {
                font-size: 14px;
            }
        }
        .footer-quick-links {
            li {
                a {
                    font-size: 13px;
                }
            }
        }
    }
    .copyright-area {
        margin-top: 30px;
        text-align: center;

        p {
            font-size: 14px;
        }
        ul {
            text-align: center;
            margin-top: 15px;

            li {
                font-size: 14px;
            }
        }
    }

}

@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {

    .top-header {
        .top-header-right-side {
            ul li:last-child {
                display: inline-block;
            }
        }
    }

    .main-banner-content {
        .btn-box {
            .default-btn {
                display: inline-block;
                margin-right: 5px;
                width: auto;
            }
            .optional-btn {
                width: auto;
                display: inline-block;
                margin: {
                    top: 0;
                    left: 5px;
                };
            }
        }
    }

    .about-image {
        padding-bottom: 90px;

        img {
            width: auto;

            &:nth-child(2) {
                position: absolute;
                border-radius: 0 10px 0 0;
                margin-top: 0;
            }
        }
    }
    .about-img {
        padding: {
            right: 8px;
            left: 50px;
            bottom: 50px;
        };
        .text {
            position: absolute;
            right: 0;
            bottom: 42px;
        }
        img {
            width: auto;
        }
        .shape {
            display: block;
        }
    }

    .subscribe-form {
        form {
            button {
                position: absolute;
                right: 2.3px;
                top: 2.3px;
                margin-top: 0;
                display: inline-block;
                width: auto;
            }
        }
    }

    .case-study-details-desc {
        .case-study-details-info {
            .single-info-box {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    .prev-link-wrapper {
        width: auto;
    }
    .next-link-wrapper {
        margin-top: 0;
    }

    .home-slides {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 25px;

                    &.owl-next {
                        left: auto;
                        right: 25px;
                    }
                }
            }
        }
    }

    .find-agent-content {
        .box {
            padding: {
                left: 15px;
                right: 15px;
            };
        }
    }
    .find-agent-contact-info {
        margin-top: 5px;

        ul {
            li {
                margin: {
                    right: 20px;
                    left: 20px;
                };
                span {
                    font-size: 15px;
                    margin-bottom: 3px;
                }
                a {
                    font-size: 16px;
                }
            }
        }
    }

    .why-choose-us-content {
        .content {
            .features-list {
                text-align: left;

                li {
                    padding-left: 75px;

                    .icon {
                        position: absolute;
                        left: 15px;
                        top: 35px;
                        margin: {
                            left: 0;
                            right: 0;
                            bottom: 0;
                        };
                    }
                }
            }
        }
    }

    .why-choose-us-content {
        .content {
            max-width: 600px;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }

    .achievements-content {
        .title {
            p {
                max-width: 540px;
                margin: {
                    left: auto;
                    right: auto;
                };
            }
        }
        .row {
            padding: {
                left: 50px;
                right: 50px;
            };
        }
    }

    .blog-details-desc {
        .article-footer {
            text-align: left;

            .article-tags {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
            .article-share {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                margin-top: 0;

                .social {
                    text-align: right;
                }
            }
        }
    }

    .copyright-area {
        text-align: left;

        ul {
            text-align: right;
            margin-top: 0;
        }
    }

}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {

    body {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }
    .default-btn {
        font-size: 14px;
    }
    .optional-btn {
        font-size: 14px;
    }
    .ptb-100 {
        padding: {
            top: 70px;
            bottom: 70px;
        };
    }
    .pb-70 {
        padding-bottom: 40px;
    }
    .section-title {
        margin-bottom: 50px;

        .sub-title {
            font-size: 16px;
        }
        h2 {
            font-size: 35px;
        }
        p {
            max-width: 590px;
            font-size: 15px;
        }
    }

    .top-header {
        text-align: center;
        padding: {
            top: 20px;
            bottom: 20px;
        };
        .top-header-right-side {
            text-align: center;
            margin-top: 10px;
        }
    }
    .top-header {
        .top-header-logo {
            display: none;
        }
    }
    .header-style-three {
        .top-header {
            .top-header-right-side {
                margin-top: 0;
            }
        }
    }
    .header-style-four {
        .top-header {
            .top-header-right-side {
                margin-top: 0;
            }
        }
    }

    .main-banner {
        height: 100%;
        padding: {
            top: 320px;
            bottom: 250px;
        };
    }
    .main-banner-content {
        max-width: 670px;
        text-align: center;
        margin: {
            left: auto;
            top: 0;
            right: auto;
        };
        .sub-title {
            font-size: 15px;
        }
        h1 {
            font-size: 45px;

            br {
                display: none;
            }
        }
        p {
            max-width: 520px;
            margin: {
                left: auto;
                right: auto;
            };
        }
        .btn-box {
            margin-top: 30px;
        }
    }
    .banner-content {
        max-width: 630px;
        margin: {
            top: 0;
        };
        .sub-title {
            font-size: 15px;
        }
        h1 {
            font-size: 45px;

            br {
                display: none;
            }
        }
        p {
            max-width: 520px;
        }
        .btn-box {
            margin-top: 30px;
        }
    }
    .home-slides {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 35px;
                    top: auto;
                    transform: unset;
                    bottom: 120px;

                    &.owl-next {
                        left: auto;
                        right: 35px;
                    }
                }
            }
        }
    }
    .home-slides-two {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 35px;
                    top: auto;
                    transform: unset;
                    bottom: 35px;

                    &.owl-next {
                        left: auto;
                        right: 35px;
                    }
                }
            }
        }
    }
    .banner-section {
        height: 100%;
        padding: {
            top: 320px;
            bottom: 170px;
        };
        .main-banner-content {
            margin-top: 0;
        }
    }
    .main-banner-section {
        height: 100%;
        padding: {
            top: 300px;
            bottom: 205px;
        };
        .main-banner-content {
            margin-top: 0;
        }
    }
    .shape1, .shape2, .shape3, .shape4 {
        display: none;
    }

    .services-boxes-area {
        margin-top: -100px;
        margin-bottom: -30px;
    }
    .single-box {
        margin-bottom: 30px;

        .content {
            border-radius: 33% 33% 0 0;
            padding: 110px 20px 30px;

            h3 {
                font-size: 18px;
            }
        }
    }

    .about-title {
        border-right: none;
        padding-right: 0;
        text-align: center;

        span {
            font-size: 16px;
        }
        h2 {
            font-size: 35px;
        }
    }
    .about-text {
        padding-left: 0;
        text-align: center;
        margin-top: 15px;

        p {
            font-size: 15px;
        }
        .read-more-btn {
            font-size: 14px;
        }
    }
    .single-about-box {
        padding: 25px;

        h3 {
            font-size: 18px;
        }
    }
    .about-content {
        padding-left: 0;
        text-align: center;
        margin: {
            top: 30px;
        };
        span {
            font-size: 16px;
        }
        h2 {
            font-size: 35px;
        }
    }
    .about-img {
        img {
            width: 100%;
        }
        .shape {
            img {
                width: auto;
            }
        }
    }

    .services-slides {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 0;
                    font-size: 18px;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    top: auto;
                    transform: unset;
                    bottom: -14px;

                    &.owl-next {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
    .single-services-box {
        .icon {
            font-size: 55px;
            margin-top: -13px;
        }
        h3 {
            font-size: 18px;
        }
        .read-more-btn {
            font-size: 14px;
        }
    }
    .services-box {
        .content {
            h3 {
                font-size: 18px;
            }
            .read-more-btn {
                font-size: 14px;
            }
        }
    }

    .why-choose-us-image {
        height: auto;
        background: {
            image: unset !important;
        };
        img {
            width: auto !important;
            display: inline-block !important;
        }
    }
    .why-choose-us-slides {
        height: auto;

        .owl-stage-outer {
            height: auto;

            .owl-stage {
                height: auto;

                .owl-item {
                    height: auto;
                }
            }
        }
    }
    .why-choose-us-content {
        .content {
            max-width: 100%;
            padding: {
                left: 35px;
                top: 50px;
                bottom: 70px;
                right: 70px;
            };
            .title {
                text-align: center;

                .sub-title {
                    font-size: 16px;
                }
                h2 {
                    font-size: 35px;
                }
                p {
                    font-size: 15px;
                }
            }
            .features-list {
                li {
                    span {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .quote-content {
        padding-right: 0;

        h2 {
            font-size: 35px;
        }
        p {
            font-size: 15px;
            max-width: 520px;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    .quote-list-tab {
        margin: {
            left: 15px;
            top: 35px;
        };
    }
    .quote-boxes-content {
        .image {
            display: none;
        }
    }

    .ctr-area {
        padding: {
            top: 100px;
            bottom: 100px;
        };
    }
    .ctr-content {
        h2 {
            font-size: 35px;
        }
    }

    .feedback-slides {
        .client-feedback {
            .single-feedback {
                p {
                    max-width: 690px;
                    font-size: 25px;
                }
            }
        }
        .next-arrow, .prev-arrow {
            display: none !important;
        }
    }

    .our-mission-area {
        .col-lg-3 {
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 2;
            }
            &:nth-child(3) {
                order: 4;
            }
            &:nth-child(4) {
                order: 3;
            }
        }
    }

    .find-agent-content {
        padding-right: 0;

        .box {
            text-align: center;
            padding-left: 20px;

            .icon {
                font-size: 30px;
                position: relative;
                left: 0;
                top: 0;
                border-radius: 50%;
                width: 75px;
                height: 75px;
                text-align: center;
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 20px;
                };
            }
            h3 {
                margin-bottom: 8px;
                font-size: 18px;
            }
        }
    }
    .find-agent-contact-info {
        padding-left: 0;
        max-width: 565px;
        margin: {
            left: auto;
            right: auto;
            top: 35px;
        };
        ul {
            li {
                padding-left: 90px;
                display: inline-block;
                margin: {
                    top: 0;
                    bottom: 0;
                    right: 15px;
                    left: 15px;
                };
                .icon {
                    width: 75px;
                    height: 75px;
                    line-height: 75px;
                    font-size: 30px;
                }
                &:nth-child(2) {
                    display: none;
                }
                &::before {
                    display: none;
                }
                span {
                    font-size: 15px;
                }
                a {
                    font-size: 20px;
                }
            }
        }
    }

    .single-team-box {
        .content {
            h3 {
                font-size: 18px;
            }
            span {
                font-size: 14px;
            }
        }
    }

    .achievements-content {
        padding: {
            top: 70px;
            bottom: 70px;
            left: 35px;
            right: 35px;
        };
        .title {
            .sub-title {
                font-size: 16px;
            }
            h2 {
                font-size: 35px;
            }
            p {
                font-size: 15px;
                max-width: 590px;
            }
        }
    }
    .single-achievements-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block !important;
        }
    }
    .divider {
        display: none;
    }
    .achievements-image-slides {
        height: auto;

        .owl-stage-outer {
            height: auto;

            .owl-stage {
                height: auto;

                .owl-item {
                    height: auto;
                }
            }
        }
    }

    .single-funfact {
        i {
            font-size: 45px;
            margin-top: -10px;
        }
        h3 {
            font-size: 35px;

            .sign-icon {
                font-size: 35px;
            }
        }
        p {
            font-size: 15px;
            margin-top: 4px;
        }
    }

    .single-blog-post {
        .post-content {
            h3 {
                font-size: 18px;
            }
        }
    }

    .subscribe-content {
        margin-bottom: 20px;
        text-align: center;

        h2 {
            font-size: 30px;
        }
    }
    .subscribe-form {
        padding-left: 0;
    }

    .page-title-area {
        height: 100%;
        padding: {
            bottom: 80px;
            top: 280px;
        };
    }
    .page-title-content {
        margin-top: 0;

        h2 {
            font-size: 35px;
        }
    }

    .insurance-details-header {
        .content {
            h3 {
                margin-bottom: 13px;
                font-size: 20px;
            }
        }
        .image {
            margin-top: 30px;
        }
    }
    .insurance-details-desc {
        h3 {
            font-size: 20px;
        }
    }

    .blockquote, blockquote {
        p {
            font-size: 20px !important;
        }
    }

    .single-case-study-box {
        .case-study-info {
            .title {
                font-size: 18px;
            }
        }
    }

    .case-study-details-desc {
        .features-text {
            h4 {
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 600;
            }
        }
        .case-study-details-info {
            margin-top: 5px;

            .single-info-box {
                -ms-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
                margin-top: 30px;
                max-width: 33.333333%;
            }
        }
        h3 {
            font-size: 20px;
        }
    }

    .single-events-box {
        .events-box {
            .events-image {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;

                .image {
                    background-image: unset;
                    height: auto;

                    img {
                        display: inline-block;
                    }
                }
            }
            .events-content {
                -ms-flex: 0 0 65%;
                flex: 0 0 65%;
                max-width: 65%;

                .content {
                    h3 {
                        font-size: 18px;
                    }
                }
            }
            .events-date {
                -ms-flex: 0 0 35%;
                flex: 0 0 35%;
                max-width: 35%;
            }
        }
    }

    .events-details-header {
        h3 {
            font-size: 20px;
        }
        .back-all-events {
            margin-bottom: 12px;
            font-size: 13px;
        }
    }

    .widget-area {
        .widget {
            .widget-title {
                font-size: 18px;
            }
        }
    }

    .faq-image {
        background-image: unset;

        img {
            display: inline-block;
        }
    }
    .faq-accordion {
        padding-bottom: 0;

        .sub-title {
            font-size: 16px;
        }
        h2 {
            max-width: 100%;
            font-size: 35px;
        }
    }

    .widget-area {
        margin-top: 40px;
    }

    .error-area {
        height: 100%;
        padding: {
            top: 150px;
            bottom: 150px;
        };
    }

    #map {
        width: 100%;
        height: 400px;
        margin-top: 40px;
    }
    .contact-info {
        margin-top: 40px;

        .contact-info-content {
            h3 {
                font-size: 18px;
            }
            h2 {
                font-size: 35px;
            }
        }
    }

    .blog-details-desc {
        .article-content h3 {
            font-size: 20px;
        }
    }
    .comments-area {
        .comments-title {
            font-size: 20px;
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 20px;
            }
        }
    }

    .join-area {
        margin-top: 70px;
        padding-top: 70px;

        .col-lg-5 {
            order: 2;
        }
        .col-lg-7 {
            order: 1;
        }
        &::before {
            height: 100%;
        }
    }
    .join-content {
        text-align: center;
        top: 0;
        margin: {
            left: auto;
            bottom: 30px;
            right: auto;
        };
        h2 {
            font-size: 35px;
        }
    }

    .single-footer-widget {
        h3 {
            font-size: 18px;
        }
    }

}

@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {

    .top-header {
        .top-header-right-side {
            ul {
                li {
                    padding-left: 45px;
                    font-size: 14px;
                    margin-right: 10px;

                    .icon {
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .main-banner-content {
        max-width: 730px;

        .sub-title {
            font-size: 15px;
        }
        h1 {
            font-size: 45px;
        }
    }
    .home-slides {
        &.owl-theme {
            .owl-nav {
                position: absolute;
                right: 25px;
                bottom: 195px;

                [class*=owl-] {
                    position: relative;
                    left: 0;
                    top: 0;
                    transform: unset;
                    bottom: 0;
                    margin-left: 20px !important;

                    &.owl-next {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
    .home-slides-two {
        &.owl-theme {
            .owl-nav {
                position: absolute;
                right: 25px;
                bottom: 25px;

                [class*=owl-] {
                    position: relative;
                    left: 0;
                    top: 0;
                    transform: unset;
                    bottom: 0;
                    margin-left: 20px !important;

                    &.owl-next {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }

    .single-about-box {
        padding: 30px 15px;

        h3 {
            font-size: 17px;
        }
    }

    .services-slides {
        &.owl-theme .owl-nav {
            display: none;
        }
    }

    .single-box {
        .content {
            padding: 110px 15px 35px;

            h3 {
                font-size: 17px;
            }
        }
    }

    .why-choose-us-content {
        .content {
            max-width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .mission-text {
        padding: {
            top: 50px;
            bottom: 50px;
            left: 15px;
            right: 15px;
        };
    }

    .single-funfact {
        h3 {
            font-size: 40px;
        }
    }

    .find-agent-content {
        .box {
            .icon {
                width: 90px;
                height: 155px;
            }
        }
    }

    .quote-boxes-content {
        .image {
            display: none;
        }
    }

    .sidebar-modal {
        .sidebar-modal-inner {
            width: 50%;
        }
    }

    .team-slides {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: -20px;

                    &.owl-next {
                        left: auto;
                        right: -20px;
                    }
                }
            }
        }
    }

    .faq-accordion {
        padding: {
            top: 30px;
            bottom: 30px;
            left: 30px;
            right: 30px;
        };
    }

}

@media only #{$media} and ($feature_min : $value_seven) and ($feature_max : $value_eight) {

    .services-slides {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: -30px;

                    &.owl-next {
                        left: auto;
                        right: -30px;
                    }
                }
            }
        }
    }

}
